const keys = [
[ // 0
],
[ // 1
['OL','Ol','I'],
['SONF','sonf','raygne'],
['VORSG','vorsg,','over you'],
['GOHO','gohó','sayeth'],
['IAD','Iad','the God'],
['BALT','balt','of Justice'],
['LANSH','lansh','in powre exalted'],
['CALZ','calz','above the firmaments'],
['VONPHO','vonpho,','of wrath:'],
['SOBRA','sobra','in Whose'],
['ZOL','z-ol','hands'],
['ROR','ror','the Sonne'],
['I','i','is'],
['TA','ta','as'],
['NAZPSAD','nazpsad','a sword'],
['OD','','and'],
['GRAA','Graa','the Moon'],
['TA','ta','as'],
['MALPRG','Malprg','a through-trusting fire'],
['DS','ds','which'],
['HOLQ','hol-q','measureth'],
['QAA','Qa-a','your garments'],
['NOTHOA','nothóa','in the mydst'],
['ZIMZ','zimz','of my vestures,'],
['OD','Od','and'],
['COMMAH','commah','trussed you together'],
['TA','ta','as'],
['NOBLOH','nobloh','the palms'],
['ZIEN','zien:','of my hands:'],
['SOBA','Soba','Whose'],
['THIL','thil','seats'],
['GNONP','gnonp','I garnished'],
['PRGE','prge','with the fire'],
['ALDI','aldi','of gathering'],
['DS','Ds','and'],
['URBS','urbs','beautified'],
['OBOLEH','óbóleh','your garments'],
['GRSAM','grsam:','with admiration,'],
['CASARM','Casarm','to whom'],
['OHORELA','ohoréla','I made a law'],
['CABA','cabá','to govern'],
['PIR','pir','the holy ones,'],
['DS','Ds','and'],
['ZONRENSG','zonrensg','delivered you'],
['CAB','cab','a rod'],
['ERM','erm','with'],
['IADNAH','Jadnah:','the ark of knowledge.'],
['PILAH','Pilah','Moreover'],
['FARZM','farzm','you lifted up your voyces'],
['OD','','and'],
['ZNRZA','znrza','sware'],
['ADNA','adna','obedience'],
['OD','','and'],
['GONO','gono','faith'],
['IADPIL','Iäl','to him'],
['DS','Ds','that'],
['HOM','hom','liveth'],
['OD','','and'],
['TOH','tóh','triumpeth,'],
['SOBA','Soba','whose'],
['IAOD','','beginning'],
['IPAM','Ipam','is not'],
['OD','','nor'],
['UL','Lu','ende'],
['IPAMIS','Ipâmis','can not be,'],
['DS','Ds','which'],
['LOHOLO','lóhólo','shyneth'],
['VEP','dep','as a flame'],
['ZOMD','zomd','in the myddst'],
['POAMAL','Poamal','of your pallace'],
['OD','od','and'],
['SONF','bogpa','rayngneth'],
['AAI','aäl','amongst you'],
['TA','ta','as'],
['PIAP','piap','the ballance'],
['BALTOH','piamos','of righteousnes,'],
['OD','od','and'],
['VAOAN','vaoan','truth:'],
['ZACAR','ZACARe','Move'],
['CA','c-a','therfore,'],
['OD','od','and'],
['ZAMRAN','ZAMRAN','shew yourselves:'],
['ODO','odo','open'],
['CICLE','cicle','the Mysteries'],
['QAA','Qaa','of your Creation:'],
['ZORGE','zorge,','Be friendely unto me:'],
['LAP','lap','for'],
['ZIRDO','zirdo','I am'],
['NOCO','noco','the servant'],
['MAD','MAD','of the same your God:'],
['HOATH','Hoath','the true Worshipper'],
['IAIDA','Jaida.','of the Highest.'],
],
[ // 2
['ADGT','','Can'],
['UPAAH','','the wings'],
['ZONG','','of the windes'],
['OM','',''],
['FAAIP','','understand'],
['SALD','','your voyces of wonder'],
['VIV','','O you the second'],
['L','','of the first,'],
['SOBAM','','Whome'],
['IALPRG','','the burning flames'],
['IZAZAZ','','have framed'],
['PIADPH','','within the depths of my Jaws,'],
['CASARMA','','whome'],
['ABRAMG','','I have prepared'],
['TA','','as'],
['TALHO','','Cupps'],
['PARACLEDA','','for a wedding'],
['Q','','or'],
['TA','','as'],
['LORSLQ','','the flowres'],
['TURBS','','in their beawty'],
['OOGE','','for the Chamber'],
['BALTOH','','of righteousnes'],
['GIUI','','Stronger'],
['CHIS','','are'],
['LUSD','','your fete'],
['ORRI','','then the barren stone:'],
['OD','','And'],
['MICALP','','myghtier'],
['CHIS','','are'],
['BIA','','your voices'],
['OZONGON','','then the manifold windes.'],
['LAP','','For'],
['NOAN','','you are become'],
['TROF','','a buylding'],
['CORS','','such'],
['TA','','as'],
['GE','','is not'],
['OQ','','but'],
['MANIN','','in the mynde'],
['IAIDON','','of the all powerfull.'],
['TORZU','','Arrise'],
['GOHEL','','sayeth the First'],
['ZACAR','','Move'],
['CA','','therefore'],
['CNOQOD','','unto his Servants:'],
['ZAMRAN','','Shew yourselves'],
['MICALZO','','in powre:'],
['OD','','And'],
['OZAZM','','make me'],
['VRELP','','a strong Seething:'],
['LAP','','for'],
['ZIR','','I am'],
['IOIAD','','of him that liveth forever.'],
],
[ // 3
['MICMA','','Behold'],
['GOHO','','sayeth'],
['IAD','','your God,'],
['ZIR','','I am'],
['COMSELH','','a Circle'],
['AZIEN','','on Whose hands'],
['BIAB','','stand'],
['OS','','12'],
['LONDOH','','Kingdoms.'],
['NORZ','','Six'],
['CHIS','','are'],
['OTHIL','','the seats'],
['GIGIPAH','','of living breath,'],
['UNDL','','the rest'],
['CHIS','','are'],
['TA','','as'],
['PUIM','','sharp sickles:'],
['Q','','or'],
['MOSPLEH','','the horns'],
['TELOCH','','of death'],
['QUIIN','','wherein'],
['TOLTORG','','the Creatures of the earth'],
['CHIS','','are'],
['ICHISGE','','to are not'],
['M','','Except'],
['OZIEN','','myne own hand'],
['DS','','which'],
['BRGDA','','slepe'],
['OD','','and'],
['TORZUL','','shall ryse.'],
['ILI','','In the first'],
['EOL','','I made you'],
['BALZARG','','stuards'],
['OD','','and'],
['AALA','','placed you'],
['THILN','','in seats'],
['OS','','12'],
['NETAAB','','of government,'],
['DLUGA','','giving'],
['VOMZARG','','unto every one of you'],
['LONSA','','powre'],
['CAPIMALI','','successively'],
['VORS','','over'],
['CLA','','456,'],
['HOMIL','','the true ages'],
['COCASB','','of tyme,'],
['FAFEN','','to the intent that,'],
['IZIZOP','','from your highest vessels'],
['OD','','and'],
['MIINOAG','','the Corners'],
['DE','','of'],
['GNETAAB','','your governments,'],
['VAUN','','you might work'],
['NANAEEL','','my powre:'],
['PANPIR','','powring downe'],
['MALPIRGI','','the fires of life and encrease,'],
['PILD','','continually'],
['CAOSG','','on the earth'],
['NOAN','','Thus you are become'],
['UNALAH','','the skirts'],
['BALT','','of Justice'],
['OD','','and'],
['VOOAN','','Truth.'],
['DOOIAP','','In the name'],
['MAD','','of the same, your God'],
['GOHOLOR','','Lift up,'],
['GOHUS','','I say,'],
['AMIRAN','','yourselves.'],
['MICMA','','Behold'],
['IEHUSOZ','','his mercies'],
['CACACOM','','florish'],
['OD','','and'],
['DOOAIN','','Name'],
['NOAR','','is become'],
['MICAOLZ','','mighty'],
['AAIOM','','amongst us'],
['CASARMG','','In whom'],
['GOHIA','','we say'],
['ZACAR','','Move,'],
['UNIGLAG','','Descend'],
['OD','','and'],
['IMUAMAR','','apply yourselves unto us'],
['PUGO','','as unto'],
['PLAPLI','','partakers'],
['ANANAEL','','of the secret wisdom'],
['QAAN','','of your Creation.'],
],
[ // 4
['','','I have set'],
['','','my fete'],
['','','in the Sowth'],
['','','and'],
['','','have loked abowt me'],
['','','sayng,'],
['','','are not'],
['','','the Thunders of encrease'],
['','','numbred'],
['','','33,'],
['','','which'],
['','','rayne'],
['','','in the second'],
['','','Angle,'],
['','','under whom'],
['','','I have placed'],
['','','9639'],
['','','Whome'],
['','','None'],
['','','hath yet numbred,'],
['','','but'],
['','','one,'],
['','','in whome'],
['','','the seecond beginning of things'],
['','','are'],
['','','and'],
['','','wax strong'],
['','','which'],
['','','allso'],
['','','successively'],
['','','are'],
['','','the number of time:'],
['','','and'],
['','','their powres'],
['','','are'],
['','','as'],
['','','the first'],
['','','456:'],
['','','Arrise,'],
['','','you sonns'],
['','','of pleasure,'],
['','','and'],
['','','viset'],
['','','the earth:'],
['','','for'],
['','','I am'],
['','','the Lord'],
['','','your God,'],
['','','which'],
['','','is,'],
['','','and'],
['','','liveth.'],
['','','In the name'],
['','','of the Creator'],
['','','Move,'],
['','','and'],
['','','shew yourselves'],
['','','as pleasant deliverers'],
['','','That you may praise him'],
['','','amongst'],
['','','the sonnes'],
['','','of men.'],
],
[ // 5
['','','The mighty sounds'],
['','','have entered'],
['','','into the third'],
['','','angle,'],
['','','and'],
['','','are become'],
['','','as'],
['','','olives'],
['','','in the olive mount'],
['','','looking with gladnes'],
['','','upon the earth'],
['','','and'],
['','','dwelling'],
['','','in the brightnes of the heavens'],
['','','as'],
['','','continuall comfortors,'],
['','','unto whom'],
['','','I fastened'],
['','','pillers'],
['','','of gladnes'],
['','','19'],
['','','and'],
['','','gave them'],
['','','vessels'],
['','','to water'],
['','','the earth'],
['','','with her creatures,'],
['','','and'],
['','','they are'],
['','','the brothers'],
['','','of the first'],
['','','and'],
['','','second'],
['','','and'],
['','','the beginning'],
['','','of their own seats'],
['','','which'],
['','','are garnished'],
['','','with continually burning lamps'],
['','','69636'],
['','','whose'],
['','','numbers'],
['','','are'],
['','','as'],
['','','the first'],
['','','the endes'],
['','','and'],
['','','the contents of tyme.'],
['','','Therefore'],
['','','Come you'],
['','','and'],
['','','obey'],
['','','your creation,'],
['','','viset us'],
['','','in peace'],
['','','and'],
['','','comfort'],
['','','Conclude us'],
['','','as receivers'],
['','','of your mysteries:'],
['','','for why?'],
['','','Our Lord and Mr.'],
['','','is'],
['','','all one.'],
],
[ // 6
['','','The spirits'],
['','','of ye 4th'],
['','','Angle'],
['','','are'],
['','','Nine,'],
['','','Mighty'],
['','','in the firmaments of waters,'],
['','','Whome'],
['','','the first'],
['','','hath planted'],
['','','a torment'],
['','','to the wicked'],
['','','and'],
['','','a garland'],
['','','to the righteous'],
['','','giving unto them'],
['','','fyrie darts'],
['','','to vanne'],
['','','the earth'],
['','','and'],
['','','7699'],
['','','continuall Workmen'],
['','','whose'],
['','','courses'],
['','','viset'],
['','','with comfort'],
['','','the earth'],
['','','and'],
['','','are'],
['','','in government'],
['','','and'],
['','','contynuance'],
['','','as'],
['','','the second'],
['','','and'],
['','','the third'],
['','','Wherefore'],
['','','hearken unto'],
['','','my voyce'],
['','','I have talked of you'],
['','','and'],
['','','I move you'],
['','','in power and presence,'],
['','','whose'],
['','','works'],
['','','shall be'],
['','','a song of honor'],
['','','and'],
['','','the praise'],
['','','of your God'],
['','','in your Creation.'],
],
[ // 7
['','','The East'],
['','','is'],
['','','a howse'],
['','','of virgins'],
['','','singing praises'],
['','','amongst'],
['','','the flames of the first glory,'],
['','','wherein'],
['','','the Lord'],
['','','hath opened his mouth'],
['','','and'],
['','','they are become'],
['','','28'],
['','','Living dwellings'],
['','','in whom'],
['','','the strength of men'],
['','','rejoyceth'],
['','','and'],
['','','they are appareled'],
['','','with ornaments of brightnes'],
['','','such'],
['','','as'],
['','','work'],
['','','wonders'],
['','','on all'],
['','','creatures'],
['','','Whose'],
['','','Kingdoms'],
['','','and'],
['','','continuance'],
['','','are'],
['','','as'],
['','','the third'],
['','','and'],
['','','fourth'],
['','','strong towres'],
['','','and'],
['','','places of comfort'],
['','','The seats'],
['','','of mercy'],
['','','and'],
['','','continuance.'],
['','','O you Servants'],
['','','of Mercy,'],
['','','Move,'],
['','','Appeare,'],
['','','sing praises'],
['','','unto the Creator:'],
['','','And'],
['','','be mighty'],
['','','amongst us'],
['','','For'],
['','','to this remembrance'],
['','','is given'],
['','','powre'],
['','','and'],
['','','our strength'],
['','','waxeth strong'],
['','','in our Comforter.'],
],
[ // 8
['','','The Midday'],
['','','the first'],
['','','is'],
['','','as'],
['','','the third heaven'],
['','','made'],
['','','of Hiacynth Pillers'],
['','',''],
['','','26'],
['','','in whome'],
['','','the Elders'],
['','','are'],
['','','become strong'],
['','','which'],
['','','I have prepared'],
['','','for my own righteousnes'],
['','','sayth'],
['','','the Lord'],
['','','whose'],
['','','long contynuance'],
['','','shall be'],
['','','as'],
['','','bucklers'],
['','','to the stowping'],
['','','Dragons'],
['','','and'],
['','','like unto the harvest'],
['','','of a wyddow.'],
['','','How many'],
['','','are'],
['','','there'],
['','','which'],
['','','remayn'],
['','','in the glorie'],
['','','of the earth'],
['','','which'],
['','','are'],
['','','and'],
['','','shall not see'],
['','','death'],
['','','untyll'],
['','','this'],
['','','howse'],
['','','fall'],
['','','and'],
['','','the Dragon'],
['','','snyck.'],
['','','Come away,'],
['','','for'],
['','','the Thunders'],
['','','have spoken:'],
['','','Come away,'],
['','','for'],
['','','the Crownes'],
['','','of the Temple'],
['','','and'],
['','','the coat'],
['','','of him that is, was and shall be crowned'],
['','','are divided'],
['','','Come'],
['','','Appeare'],
['','','to the terror'],
['','','of the earth'],
['','','and'],
['','','to our comfort'],
['','','and'],
['','','of such'],
['','','as'],
['','','are prepared.'],
],
[ // 9
['','','A mighty'],
['','','garde'],
['','','of fire'],
['','','with two-edged swords'],
['','','flaming'],
['','','(which'],
['','','have'],
['','','viols'],
['','',':8:'],
['','','of wrath'],
['','','for two tymes'],
['','','and'],
['','','a half:'],
['','','whose'],
['','','wings'],
['','','are'],
['','','of wormwood,'],
['','','and'],
['','','of the marrow'],
['','','of salt,)'],
['','','have setled'],
['','','their feete'],
['','','in the West,'],
['','','and'],
['','','are'],
['','','measured'],
['','','with their Ministers'],
['','','9996.'],
['','','These'],
['','','gather up'],
['','','the moss'],
['','','of the earth'],
['','','as'],
['','','the rich'],
['','','man'],
['','','doth'],
['','','his threasor:'],
['','','Cursed'],
['','','ar they'],
['','','whose'],
['','','iniquities'],
['','','they are'],
['','','in their eyes'],
['','','are'],
['','','milstones'],
['','','greater'],
['','','than the earth'],
['','','And'],
['','','from their mowthes'],
['','','rune'],
['','','seas'],
['','','of blud:'],
['','','Their heds'],
['','','are covered'],
['','','with diamond:'],
['','','and'],
['','','uppon'],
['','','their heds'],
['','','are'],
['','','marble'],
['','','sleves.'],
['','','Happie is he,'],
['','','on whome'],
['','','they frown not.'],
['','','For why?'],
['','','The God'],
['','','of righteousnes,'],
['','','rejoyceth'],
['','','in them.'],
['','','Come away'],
['','','and'],
['','','not'],
['','','your Viols'],
['','','For'],
['','','the tyme'],
['','','is'],
['','','such'],
['','','as'],
['','','requireth'],
['','','comfort.'],
],
[ // 10
['','','The Thunders of Judgement and Wrath'],
['','','are'],
['','','numbred'],
['','','and'],
['','','are haborowed'],
['','','in the North'],
['','','in the likenes'],
['','','of an oke'],
['','','whose'],
['','','branches'],
['','','are'],
['','','Nests'],
['','','22'],
['','','of lamentation'],
['','','and'],
['','','weaping'],
['','','Layd up'],
['','','for'],
['','','the earth'],
['','','which'],
['','','burn'],
['','','night'],
['','','and'],
['','','day:'],
['','','and'],
['','','vomit out'],
['','','the heds'],
['','','of scorpions'],
['','','and'],
['','','live sulphur'],
['','','myngled'],
['','','with poyson'],
['','','These'],
['','','be'],
['','','The Thunders'],
['','','that'],
['','','5678'],
['','','tymes'],
['','','in the 24th part'],
['','','of a moment'],
['','','rore'],
['','','with a hundred'],
['','','mighty'],
['','',''],
['','','earthquakes'],
['','','and'],
['','','a thousand'],
['','','tymes'],
['','','as many'],
['','','surges'],
['','','which'],
['','','rest not'],
['','','neyther'],
['','','know'],
['','','at any'],
['','',''],
['','','tyme'],
['','','here'],
['','','One'],
['','','rock'],
['','','bringeth forth'],
['','','1000'],
['','','even as'],
['','','the hart'],
['','','of man'],
['','','doth'],
['','','his thowghts'],
['','','wo'],
['','','wo'],
['','','wo'],
['','','wo'],
['','','wo'],
['','','wo'],
['','','yea'],
['','','wo'],
['','','be to the earth'],
['','','For'],
['','','her iniquitie'],
['','','is'],
['','','was'],
['','','and shal be'],
['','','great.'],
['','','Come away'],
['','','but'],
['','','not'],
['','','your noyses.'],
],
[ // 11
['','','The mighty seat'],
['','','groaned'],
['','','and'],
['','','they were'],
['','',':5:'],
['','','thunders'],
['','','which'],
['','','flew'],
['','','into the East'],
['','','and'],
['','','the Egle'],
['','','spake'],
['','','and'],
['','','cryed with a lowde voyce'],
['','','Come awaye'],
['','','and'],
['','','they gathered them together in'],
['','','the house'],
['','','of death'],
['','','of whome'],
['','','it is measured'],
['','','and'],
['','','it'],
['','','is'],
['','','as'],
['','','they are'],
['','','whose'],
['','','number'],
['','','is'],
['','','31.'],
['','','Come away'],
['','','For'],
['','','I have prepared'],
['','','for you'],
['ZACAR','ZACARe','Move'],
['CA','cab','therfore'],
['OD','od','and'],
['ZAMRAN','ZAMRAN','shew your selves'],
['ODO','odo','open'],
['CICLE','cicle','the Mysteries'],
['QAA','Qaá','of your Creation'],
['ZORGE','Zorge','Be friendely unto me'],
['LAP','lap','for'],
['ZIRDO','zirdo','I am'],
['NOCO','noco','the servant'],
['MAD','Mad','of the same your God'],
['HOATH','Hoath','the true worshipper'],
['IAIDA','Iaida.','of the Highest.'],
],
[ // 12
['','','O you'],
['','','that'],
['','','rayne'],
['','','in the sowth'],
['','','and'],
['','','are'],
['','',':28:'],
['','','the lanterns'],
['','','of sorrow'],
['','','bynde up'],
['','','your girdles'],
['','','and'],
['','','viset us'],
['','','Bring down'],
['','','your trayn'],
['','','3663'],
['','','that'],
['','','the Lord'],
['','','may be magnified'],
['','','whose'],
['','','name'],
['','','amongst you'],
['','','is'],
['','','Wrath'],
['ZACAR','ZACARe','Move,'],
['GOHUS','gohus','I say,'],
['OD','od','and'],
['ZAMRAN','ZAMRAN,','shew yourselves'],
['ODO','odo','open'],
['CICLE','cicle','the mysteries'],
['QAA','Qáa','of your Creation'],
['ZORGE','Zorge,','be friendly unto me'],
['LAP','Lap','for'],
['ZIRDO','zirdo','I am'],
['NOCO','noco','the servant'],
['MAD','MAD','of the same your God,'],
['HOATH','Hoath','the true worshipper'],
['IAIDA','Iaida.','of the Highest.'],
],
[ // 13
['','','Oh you swords'],
['','','of the sowth'],
['','','which'],
['','','have'],
['','','42'],
['','','eyes'],
['','','to styr up'],
['','','wrath'],
['','','of synn'],
['','','making'],
['','','men'],
['','','drunken'],
['','','which'],
['','','are'],
['','','empty:'],
['','','Behold'],
['','','the promise'],
['','','of God'],
['','','and'],
['','','his powre'],
['','',''],
['','','which'],
['','','is called'],
['','','amongst you'],
['','','A bitter sting:'],
['ZACAR','ZACAR','Move'],
['OD','od','and'],
['ZAMRAN','ZAMRAN,','shew yourselves'],
['ODO','odo','Open'],
['CICLE','cicle','the mysteries'],
['QAA','Qäa','of your Creation'],
['ZORGE','Zorge,','Be friendly unto me:'],
['LAP','Lap','for'],
['ZIRDO','zirdo','I am'],
['NOCO','Noco','the servant'],
['MAD','MAD,','of the same your God'],
['HOATH','Hoath','The true worshipper'],
['IAIDA','Iaida.','of the Highest'],
],
[ // 14
['','','O you sonns'],
['','','of fury'],
['','','the dowghters'],
['','','of the Just'],
['','','which'],
['','','sit'],
['','','upon'],
['','','24'],
['','','seats'],
['','','vexing'],
['','','all'],
['','','creatures'],
['','','of the earth'],
['','','with age'],
['','','which'],
['','','have'],
['','','under you'],
['','','1636'],
['','','Behold'],
['','','the voyce'],
['','','of God'],
['','','promys'],
['','','of him'],
['','','which'],
['','','is called'],
['','','amongst you'],
['','','Furye, or Extreme Justice'],
['ZACAR','ZACAR','Move'],
['OD','od','and'],
['ZAMRAN','ZAMRAN','shew yourselves'],
['ODO','odo','open'],
['CICLE','cicle','the mysteries'],
['QAA','Qäa','of your Creation'],
['ZORGE','Zorge,','Be friendly unto me'],
['LAP','Lap','for'],
['ZIRDO','zirdo','I am'],
['NOCO','Noco','the servant'],
['MAD','MAD,','of the same your God'],
['HOATH','hoath','the true worshipper'],
['IAIDA','Iaida.','of the Highest.'],
],
[ // 15
['','','O thow'],
['','','the governor'],
['','','of the first'],
['','','flame'],
['','','under whose'],
['','','wyngs'],
['','','are'],
['','','6739'],
['','','which'],
['','','weave'],
['','','the earth'],
['','','with drynes'],
['','','which'],
['','','knowest'],
['','','of the great name'],
['','','Righteousnes'],
['','','and'],
['','','the seale'],
['','','of Honor'],
['ZACAR','ZACAR','Move'],
['OD','od','and'],
['ZAMRAN','ZAMRAN,','shew yourselves'],
['ODO','odo','open'],
['CICLE','cicle','the mysteries'],
['QAA','Qäa','of your Creation'],
['ZORGE','zorge,','Be friendely unto me'],
['LAP','Lap','for'],
['ZIRDO','zirdo','I am'],
['NOCO','Noco','the servant'],
['MAD','MAD,','of the same your God'],
['HOATH','hoath','the true worshipper'],
['IAIDA','Iaida.','of the Highest.'],
],
[ // 16
['','','Oh thow'],
['','','second'],
['','','flame'],
['','','the house'],
['','','of Justice'],
['','','which'],
['','','hast'],
['','','thy beginning'],
['','','in glory:'],
['','','and'],
['','','shalt comfort'],
['','','the iust:'],
['','','which'],
['','','walkest'],
['','','on the earth'],
['','','with feete'],
['','','876'],
['','','that'],
['','','understand'],
['','','and'],
['','','separate'],
['','','creatures:'],
['','','great'],
['','','art'],
['','','thow'],
['','','in the God of stretch-forth-and-conquer.'],
['ZACAR','ZACAR','Move'],
['OD','od','and'],
['ZAMRAN','ZAMRAN','shew yourselves'],
['ODO','odo','Open'],
['CICLE','cicle','the mysteries'],
['QAA','Qäa','of your Creation'],
['ZORGE','zorge,','Be friendly unto me'],
['LAP','Lap','for'],
['ZIRDO','zirdo','I am'],
['NOCO','Noco','the servant'],
['MAD','Mad','of the same your God'],
['HOATH','hoath','the true worshipper'],
['IAIDA','Iaida.','of the Highest.'],
],
[ // 17
['','','O thow'],
['','','third'],
['','','flame'],
['','','whose'],
['','','wyngs'],
['','','are'],
['','','thorns'],
['','','to styr up'],
['','','vexation:'],
['','','and'],
['','','hast'],
['','','7336'],
['','','lamps living'],
['','','going'],
['','','before the'],
['','','whose'],
['','','God'],
['','','is'],
['','','Wrath in Angre'],
['','','Gyrd up'],
['','','thy loynes'],
['','','and'],
['','','harken'],
['ZACAR','ZACAR','Move'],
['OD','od','and'],
['ZAMRAN','ZAMRAN','shew yourselves'],
['ODO','odo','Open'],
['CICLE','cicle','the mysteries'],
['QAA','Qäa','of your Creation'],
['ZORGE','zorge,','Be friendely unto me'],
['LAP','Lap','for'],
['ZIRDO','zirdo','I am'],
['NOCO','Noco','the servant'],
['MAD','Mad','of the same your God'],
['HOATH','hoath','the true worshipper'],
['IAIDA','Iaida','of the Highest.'],
],
[ // 18
['','','O thow'],
['','','mighty'],
['','','light'],
['','','and'],
['','','burning flame'],
['','','of comfort'],
['','','which'],
['','','openest'],
['','','the glory'],
['','','of God'],
['','','to the center'],
['','','of the erth'],
['','','In whome'],
['','','the secrets of truth'],
['','','6332'],
['','','have'],
['','','their abiding'],
['','','which'],
['','','is called'],
['','','in thy Kingdome'],
['','','IOYE'],
['','','and'],
['','','not to be measured'],
['','','Be thow'],
['','','a wyndow'],
['','','of comfort'],
['','','vnto me.'],
['ZACAR','ZACAR','Move'],
['OD','od','and'],
['ZAMRAN','ZAMRAN','shew yourselves'],
['ODO','odo','Open'],
['CICLE','cicle','the mysteries'],
['QAA','Qäa','of your Creation'],
['ZORGE','zorge','Be friendely unto me'],
['LAP','Lap','for'],
['ZIRDO','zirdo','I am'],
['NOCO','Noco','the servant'],
['MAD','MAD','of the same your God'],
['HOATH','Hoath','the true worshipper'],
['IAIDA','Iaida.','of the Highest.'],
]
];

// eslint-disable-next-line no-sparse-arrays
const output = [ , ];
keys.forEach((key, keyNo) => {
    if (keyNo === 0)
      return;

    // eslint-disable-next-line no-sparse-arrays
    const keyObj = { key: keyNo, subkeys: [ , ] };
    output.push(keyObj);

    key.forEach((subkey, subkeyArrayIndex) => {
      keyObj.subkeys.push({
        subkey: subkeyArrayIndex+1,
        enochianLatin: subkey[0],
        enochianTransliteration: subkey[1],
        english: subkey[2],
      });
    });
});

export default output;
