import React from 'react';

import AppBar from '../AppBar';

const navPos = [
  { text: 'Enochian', to: '/enochian' }
];

const s = {
  page: {
    textAlign: 'center',
    marginLeft: '20px',
    marginRight: '20px'
  },
  custom: {
    fontWeight: 'bold'
  },
  oration: {
    textAlign: 'justify'
  },
  footnote: {
    fontSize: '80%'
  }
}

const Oration = () => (
  <div>
    <AppBar navPos={null/*navPos*/} title="Oration to God" />
    <div style={s.page}>
      <p style={s.custom}>To Be Spoken Every Day Three Times Successively<sup>1</sup></p>
      <p style={s.oration}>
O Almighty, Æternal, The True & Living God: O King of Glory, O Lord of Hosts, O Thou, the Creator
of Heaven & Earth, & of all things visible & invisible: Now, (even now, at length,) among other Thy
manifold mercies used, & to be used toward me, Thy simple servant, ____________, I most humbly
beseech Thee, in this my present petition to have mercy upon me, to have pity upon me, to have
compassion upon me.  I, faithfully & sincerely, of long time, have sought among men, in Earth, & also
by prayer (full oft & pitifully) have made suit unto Thy Divine Majesty for the obtaining of some
convenient portion of True Knowledge & Understanding of Thy laws & ordinances, established in the
natures & properties of Thy Creatures: By which Knowledge, Thy Divine Wisdom, Power & Goodness
(on Thy Creatures bestowed & to them imparted), being to me made & allure me, (for the same)
incessantly to pronounce Thy praises, to render unto Thee, most hearty thanks, to advance Thy true
honour, & to win unto Thy Name, some of Thy due Majestic Glory, among all people, & forever.  And,
whereas, it hath pleased Thee (O God) of Thy infinite Goodness, by Thy faithful & holy Spiritual
Messengers, to deliver unto me, long since (through the eye and ear of E. K.) an orderly form, &
manner of exercise Heptarchical: How, (to Thy Honour & Glory, & the Comfort of my own poor soul,
& of others, Thy faithful servants,) I may, at all times, use very many of Thy good Angels, their
counsels & helps; according to the properties of such their functions, & offices, as to them, by Thy
Divine Power, Wisdom & Goodness, is assigned, & limited: (Which orderly form, & manner of
exercise, until even now, I never found opportunity & extreme necessity, to apply myself unto,)
Therefore, I thy poor, & simple servant, do most humbly, heartily, & faithfully beseech Thy Divine
Majesty, most lovingly & fatherly to favour: and by Thy Divine Beck to further this my present
industry & endeavour to exercise myself, according to the foresaid orderly forme & manner: and, now,
(at length, but not too late) for Thy dearly deloved Son JESUS CHRIST His Sake, (O Heavenly Father) to
grant also unto me, this blessing & portion of Thy heavenly Graces: That Thou wilt fortwith, enable
me, make me apt & acceptable, (in body, soul & spirit) to enjoy always the holy & friendly
conversation, with the sensible, plain, full, & perfect help, in word & deed, of Thy Mighty, Wise &
Good Spiritual Messengers & Ministers generally: &, namely, of Blessed Michael, Blessed Gabriel,
Blessed Raphael, & Blessed Uriel; & also especially, of all those, which do appertain unto the
Heptarchical Mystery, theurgically, (as yet) & very briefly unto me declared: under the method of
Seven Mighty Kings, & their Seven Faithful & Princely Ministers, with their subjects & servants, to
them belonging.  And in this Thy great Mercy & Grace, on me bestowed, & to me confirmed, (O
Almighty God,) Thou shalt, (to the great comfort of Thy faithful servants,) approve, to Thy very
enemies, & mine, the truth & certainty of Thy manifold, most merciful promises, heretofore, made
unto me: and that Thou art the True & Almighty God, Creator of Heaven & Earth, (upon whom I do
call & in whom I put all my trust,) & Thy Ministers, to be the true, & faithful Angels of light: which
have, hitherto, principally, & according to Thy Divine providence dealth with us: And, also, I, thy poor
& simple servant, shall then, In & By Thee, be better able to serve Thee, according to Thy well
pleasing: to Thy Honour & Glory: Yea, even in these most miserable, & lamentable days.  Grant, Oh
grant, O our Heavenly Father, grant this, (I pray Thee,) for Thy only begotten Son JESUS CHRIST, His
sake: Amen, Amen, Amen.
      </p>
      <p style={s.footnote}><sup>1</sup><i>The Enochian Magick of Dr. John Dee</i>, Geoffrey James, p. 53-55.</p>
    </div>
  </div>
);

export default Oration;
